import axios from "@/plugins/axios";
import { isAxiosError, isSystemError } from "@/plugins/Error";
import {
  ComboboxChildOption,
  ComboboxOption,
  isNotUpload,
  PageResult,
  SearchService,
} from ".";

const Paths = {
  List: "api/Measurement",
  Get: (id: string, isForce: boolean | undefined = undefined) =>
    `api/Measurement/${id}?isAll=true${
      isForce === true ? "&isForce=true" : ""
    }`,
  Delete: (id: string) => `api/Measurement/${id}`,
  Save: (id: string) => `api/Measurement/${id}`,
  SaveOptions: (id: string) => `api/Measurement/${id}/options`,
  CloudUpload: (id: string) => `api/Measurement/${id}/synchronize`,
  ProjectList: "api/Project/combobox",
  EquipmentList: "api/Equipment/combobox",
  TemplateList: "api/ReportTemplate/combobox",
  Template: (id: string) => `api/ReportTemplate/${id}?isAll=true`,
  TemplateFileValidate: (id: string) => `api/ReportTemplate/${id}/validate`,
  TemplateFileValidateByProject: (id: string) =>
    `api/ReportTemplate/${id}/validate?isProject=true`,
};

export type SearchParams = {
  userName: string;
  equipmentId: string;
  templateId: string;
  projectId: string;
  start: string;
  end: string;
  createAt: string;
  updateAt: string;
  isAll: string;
  isUploaded: string;
  isPrivate: string;
  orderBy: string;
};

export type MeasurementOptionModel = {
  id: string;
  measurementId: string;
  templateOptionId: string;
  title: string;
  type: number;
  value: string;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  uploadAt: string | null;
};
export type EquipmentModel = {
  id: string;
  name: string;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  isDeleted: boolean;
};
export type TemplateModel = {
  id: string;
  equipmentId: string;
  fileName: string;
  filePath: string;
  useAdditional: boolean;
  isProject: boolean;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  isDeleted: boolean;
  options: TemplateOptionModel[];
};
export type TemplateOptionModel = {
  id: string;
  templateId: string;
  title: string;
  title2: string;
  type: number;
  order: number;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  uploadAt: string | null;
  isDeleted: boolean;
};
export type ProjectModel = {
  id: string;
  name: string;
  start: string;
  end: string;
  templateId: string;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  isDeleted: boolean;
};

export type MeasurementModel = {
  id: string;
  userId: string;
  equipmentId: string;
  templateId: string | null;
  projectId: string | null;
  userName: string | null;
  folderPath: string;
  start: string;
  end: string;
  createAt: string;
  updateAt: string;
  isDeleted: boolean;
  isPrivate: boolean;
  uploadAt: string;
  options: MeasurementOptionModel[];
  equipment: EquipmentModel;
  template: TemplateModel;
  project: ProjectModel | null;
};
export type SearchResult = {
  list: MeasurementModel[];
  page: PageResult;
};
export class MeasurementService extends SearchService<
  SearchParams,
  MeasurementModel
> {
  constructor() {
    super({ omit: ["detail"] });
  }
  protected get getPath(): string {
    return Paths.List;
  }
  async getProjects(option?: {
    isAll?: boolean;
    equipmentId?: string;
    templateId?: string;
    now?: string;
  }): Promise<ComboboxChildOption[]> {
    try {
      const record = (option ?? {}) as Record<string, string>;
      record.hasParent = "true";
      const query = new URLSearchParams(record).toString();
      const response = await axios.get<ComboboxChildOption[]>(
        `${Paths.ProjectList}?${query}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async getEquipments(option?: { isAll: string }): Promise<ComboboxOption[]> {
    try {
      const record = (option ?? {}) as Record<string, string>;
      const query = new URLSearchParams(record).toString();
      const response = await axios.get<ComboboxOption[]>(
        `${Paths.EquipmentList}?${query}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * combobox としての テンプレートの取得
   * @param option オプション
   * @returns
   */
  async getTemplates(option?: {
    isAll?: string;
    equipmentId?: string;
  }): Promise<ComboboxChildOption[]> {
    try {
      const record = (option ?? {}) as Record<string, string>;
      record.hasParent = "true";
      const query = new URLSearchParams(record).toString();
      const response = await axios.get<ComboboxChildOption[]>(
        `${Paths.TemplateList}?${query}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * テンプレートの取得
   * @param templateId
   * @returns
   */
  async getTemplate(templateId: string): Promise<TemplateModel> {
    try {
      const url = Paths.Template(templateId);
      const response = await axios.get<TemplateModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async validateTemplate(
    templateId: string,
    isProject: boolean
  ): Promise<"ok" | "ng" | "notFound"> {
    try {
      const url = isProject
        ? Paths.TemplateFileValidateByProject(templateId)
        : Paths.TemplateFileValidate(templateId);
      await axios.get<void>(url);
      return "ok";
    } catch (e) {
      console.error(e);
      if (!isAxiosError(e)) throw e;
      if (!e.response?.status) throw e;
      if (e.response?.status == 404) return "notFound";
      return "ng";
    }
  }
  async cloudUpload(id: string): Promise<boolean> {
    try {
      const url = Paths.CloudUpload(id);
      await axios.post(url);
      return true;
    } catch (e) {
      console.error(e);
      if (isSystemError(e)) throw e;
      return false;
    }
  }
  async get(
    id: string,
    isForce: boolean | undefined = undefined
  ): Promise<MeasurementModel> {
    try {
      const url = Paths.Get(id, isForce);
      const response = await axios.get<MeasurementModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async save(model: MeasurementModel): Promise<void> {
    try {
      const url = Paths.Save(model.id);
      await axios.put(url, model);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async saveOptions(model: MeasurementModel): Promise<void> {
    try {
      const url = Paths.SaveOptions(model.id);
      await axios.put(url, model);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async delete(id: string): Promise<void> {
    try {
      const url = Paths.Delete(id);
      await axios.delete(url);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
export const Measurement = new MeasurementService();

/**
 * アップロード可否
 * @param model
 * @returns
 */
export function isUploadable(model: MeasurementModel): boolean {
  const uploadAt = model.uploadAt;
  // 未アップロードで且つ 情報資産レベル2以上なら アップロード不可 情報資産レベル2に満たないならアップロード可
  if (uploadAt == null) return !model.isPrivate;
  // アップロード日 が 更新日 よりも前
  return isNotUpload(model);
}
